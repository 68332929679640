"use strict";
/*!
 *
 *  page2top.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$elem = $('#page2top');
        this.$wrapper = $('.l-wrapper');
        this.isStatic = false;
        this.borderLine = 0;
        $(window).on('scroll', function (e) {
            var top = $(e.currentTarget).scrollTop();
            var bottom = top + util.wh;
            if (!_this.isStatic && bottom >= _this.getBorder()) {
                _this.isStatic = true;
                _this.$elem.addClass('-static');
            }
            ;
            if (_this.isStatic && bottom < _this.getBorder()) {
                _this.isStatic = false;
                _this.$elem.removeClass('-static');
            }
            ;
        });
    }
    default_1.prototype.getBorder = function () {
        var top = 0;
        return this.$wrapper.offset().top + this.$wrapper.outerHeight() + top;
    };
    return default_1;
}());
exports.default = default_1;
