"use strict";
/*!
 *
 *  nav-current.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(elem, option) {
        this.setting = {
            currentClass: 'is-current',
            abs: false,
            not: undefined,
            root: '/',
            escape: [],
            add: []
        };
        this.URLPath = location.pathname + location.search;
        this.URLLocate = location.protocol + '//' + location.hostname;
        $.extend(this.setting, option);
        this.$elem = $(elem);
        this.setCurrent();
    }
    default_1.prototype.setCurrent = function () {
        var _this = this;
        this.$elem.find('a').each(function (i, elem) {
            if (_this.setting.not !== undefined && $(elem).is(_this.setting.not)) {
                return;
            }
            ;
            var href = $(elem).attr('href');
            href = href !== undefined ? href.replace(_this.URLLocate, '') : undefined;
            var escape = (function () {
                var isMatch = false;
                _this.setting.escape.forEach(function (val, i) {
                    if (href === val) {
                        isMatch = true;
                    }
                    ;
                });
                return isMatch;
            })();
            if (href !== undefined) {
                var isCurrent = (_this.URLPath.search(href) == 0);
                if (_this.setting.abs || href === _this.setting.root) {
                    isCurrent = (_this.URLPath === href);
                }
                ;
                if (isCurrent) {
                    $(elem).attr('aria-current', 'true');
                }
                else {
                    $(elem).attr('aria-current', 'false');
                }
                if (_this.setting.abs) {
                    var _URL_1 = _this.URLPath.replace(/(.+\/)(.+\.html)$/g, '$1');
                    _this.setting.add.forEach(function (val, i) {
                        if (_URL_1.search(val) != -1) {
                            _URL_1 = val;
                        }
                        ;
                    });
                    if (href === _URL_1) {
                        $(elem).attr('aria-current', 'true');
                    }
                    else {
                        var _url = _URL_1.split('/');
                        if (_url.length > 6) {
                            _url.pop();
                            _url.pop();
                            var __url = _url.join('/') + '/';
                            if (href === __url) {
                                $(elem).attr('aria-current', 'true');
                            }
                            ;
                        }
                        ;
                    }
                }
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
