"use strict";
/*!
 *
 *  menu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Menu = void 0;
var Menu = /** @class */ (function () {
    function Menu() {
        var _this = this;
        this.$menu = $('#menu');
        this.$btn = $('.l-menu__btn');
        this.top = 0;
        this.$btn.on('click', function (e) {
            e.preventDefault();
            var $menu = $(e.currentTarget).closest('.l-menu');
            if ($menu.is('[aria-expanded="true"]')) {
                $menu.attr('aria-expanded', 'false');
                $('html').css({
                    overflow: ''
                });
                document.removeEventListener('touchmove', _this.scrollControll);
            }
            else {
                $menu.attr('aria-expanded', 'true');
                $('html').css({
                    overflow: 'hidden'
                });
                document.addEventListener('touchmove', _this.scrollControll, {
                    passive: false
                });
            }
            ;
        });
    }
    Menu.prototype.scrollControll = function (e) {
        var $inner = $(e.target).closest('.l-menu__body');
        if ($inner.length > 0) {
            if ($inner.get(0).scrollHeight > $inner.innerHeight()) {
                e.stopPropagation();
            }
            else {
                e.preventDefault();
            }
            ;
        }
        else {
            e.preventDefault();
        }
    };
    return Menu;
}());
exports.Menu = Menu;
;
